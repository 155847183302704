<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/BasketV2.vue'
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import AcceptedOffer from '../../components/basket/AcceptedOfferV2.vue'
import ProductCard from '../../components/basket/ProductCardV2.vue'
import ServicesCard from '../../components/basket/ServicesCard.vue'
import ProductCaption from '../../components/basket/ProductCaption.vue'
import orderService from '../../services/order'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import SuccessAlert from '@/components/basket/SuccessAlert.vue';
import CancelOrderButton from '@/components/basket/CancelOrderButton.vue';
import StandardModal from '../../components/StandardModalV2.vue'

export default {

    components: {BasketLayout, AcceptedOffer, VuePhoneNumberInput, ProductCard, ServicesCard, ProductCaption, SuccessAlert, CancelOrderButton, StandardModal}, 
    mixins: [orderMixin, merchantMixin],
    data() {
        return {

            yousignMemberId: null,
            phoneNumber: null,
            phoneNumberValue: null,
            isPhoneNumberValid: false,
            isDisplayWaitMessage: false,
            isLoading: false,
            modalCancelOrder: false,
            yousignSignatureLink: null
        }
    },
    created() {
      
        this.$store.commit('setCurrentStep', 40)
    },
    methods: {
        sleep(ms) {
            
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        nextStep(isStillTransfer) {

            if (isStillTransfer) {

                this.$router.push({name: 'orderDelivery', params: {id: this.order.public_id}, query: {t: 1}})
            }
            else {

                this.$router.push({name: 'orderDelivery', params: {id: this.order.public_id}, query: {}})
            }
        },
        async makeTransfer() {

            this.isLoading = true
            let response = await orderService.createTransfer(this.order.public_id, this.phoneNumber)
            if (response.data.version === '3') {

                await this.sleep(2000) // Do that otherwise yousign can failed :(

                this.yousignSignatureLink = response.data.signature_link
                this.initializeYouSign()
                this.error = null
            }
            else if (response.data.version === '2') {
            
                this.yousignMemberId = response.data.member_id
            }

            this.isLoading = false
        },
        updatePhoneNumber(data) {

            this.phoneNumber = data.e164
            this.isPhoneNumberValid = data.isValid
        },
        getYousignUrl(yousignMemberId) {

            return 'https://'+process.env.VUE_APP_YOUSIGN_DOMAIN+'/procedure/sign?signatureUi='+process.env.VUE_APP_YOUSIGN_TRANSFER_SIGNATURE_UI+'&members=' + yousignMemberId
        },
        async cancelOrder() {

            try {
                
                let redirectionUrl = await orderService.cancelOrderById(this.orderId);
                window.location.href = redirectionUrl.data;

            }
            catch (error) {

                console.log(error);
            }
        },
        closeModalCancelOrder() {

            
            this.modalCancelOrder = false

            this.$tracking.logEvent('Cancel order closed', {'Order ID': this.orderId})
        },
        async initializeYouSign() {

            const { default: Yousign } = await import('../../utils/Yousign.js');

            const yousign = new Yousign({
                signatureLink: this.yousignSignatureLink,
                iframeContainerId: 'iframe-container',
                isSandbox: process.env.VUE_APP_ENVIRONMENT === 'production' ? false : true,
            });

            this.addYouSignEventListeners(yousign);
        },
        addYouSignEventListeners(yousign) {
            if (yousign) {
                
                yousign.onSuccess(() => {

                    this.nextStep(false)
                });
            }
        },
        resetSignature() {

            this.yousignSignatureLink = null
        }
    },
    computed: {

        isYousignMemberIdReady() {

            return this.yousignMemberId != null
        },
        phoneTranslations() {

            return {

                countrySelectorLabel: 'Code pays',
                countrySelectorError: 'Choisir un pays',
                phoneNumberLabel: 'Numéro de téléphone portable',
                example: 'Exemple :'
            }
        }
    },
    beforeRouteEnter(to, from, next) {

        next(vm => {
        
            if (from.name == 'orderDelivery') {

                vm.isDisplayWaitMessage = true
            }
        })
    },
    watch: {

        order() {

            if (this.order != undefined && this.order.user != undefined) this.phoneNumberValue = this.order.user.phone_number
        },
        isOrderLoaded() {

            this.$tracking.pageView('transfer', 'order', {'Order ID': this.orderId, 'Coupon Type': this.order.coupon_type})
        }
    }
}
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>
<template>
    <basket-layout>
        <div v-if="isOrderLoaded">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-7 ms-md-5">
                    <div class="col-md-11">
                        <success-alert :message="'Votre empreinte de CB a bien été validée !'" v-if="$route.query.pv == 1" />
                        <h3 class="text-light">Bon de cession</h3>
                        <p class="text-light">Dernière étape : la signature électronique du bon de cession.
                        <br/>Elle permet de garantir légalement l'offre de reprise.</p>
                        <p class="text-light">Merci de renseigner ci-dessous votre numéro de téléphone portable.<br/>Vous recevrez un code de validation par SMS.</p>
                        <p class="text-light" style="max-width:400px"><vue-phone-number-input :translations="phoneTranslations" v-model="phoneNumberValue" @update="updatePhoneNumber" :only-countries="['FR', 'BE']" :default-country-code="'FR'" /></p>
                        <button :disabled="!isPhoneNumberValid || isLoading" class="btn btn-success mb-4" type="button" @click="makeTransfer">Je signe le bon de cession <span v-if="isLoading" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span></button>
                        <div class="mb-4" v-if="isDisplayWaitMessage">
                            <div class="alert alert-info" role="alert">
                                Si vous avez déjà signé le bon de cession, un délai de quelques secondes doit être pris en compte.
                            </div>
                            <button @click="nextStep(false)" class="btn btn-success">Passer aux informations d'expédition</button>
                        </div>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :price="order.price" :merchant="order.merchant" :type="order.coupon_type" :bonus="order.brand_campaign"/>
                        <cancel-order-button v-if="order.active_counter_offer === null && order.accepted_counter_offer === null" v-model="modalCancelOrder" @click="modalCancelOrder = true"/>
                    </div>
                    <services-card v-if="merchant" :productType="order.product_type" :merchant="merchant" />
                    <product-caption/>
                </div>
            </div>
        </div>
        <transition v-if="isYousignMemberIdReady" name="modal">
            <div class="modal-mask">
                <div class="modal modal-wrapper" style="overflow-y: auto">
                    <div class="h-100 modal-dialog modal-lg" role="document">
                        <div class="h-100 modal-content"> 
                            <div class="modal-header">
                                <button @click="nextStep(true)" type="button" class="btn btn-secondary float-right ml-auto" data-dismiss="modal">Fermer</button>
                            </div>
                            <div class="h-100 modal-body">
                                <div class="h-100 embed-responsive embed-responsive-1by1">
                                    <iframe class="h-100 embed-responsive-item" :src="getYousignUrl(yousignMemberId)"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <transition v-if="yousignSignatureLink" name="modalV3">
            <div class="modal-mask">
                <div class="modal modal-wrapper" style="overflow-y: auto">
                    <div class="h-100 modal-dialog modal-lg" role="document">
                        <div class="h-100 modal-content"> 
                            <div class="modal-header">
                                <button @click="yousignSignatureLink = null" type="button" class="btn btn-secondary float-right ml-auto" data-dismiss="modal">Fermer</button>
                            </div>
                            <div class="h-100 modal-body">
                                <div class="h-100" id="iframe-container"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <standard-modal v-if="modalCancelOrder">
            <template v-slot:body>
                <p class="text-light"> Êtes vous sûr de vouloir annuler votre reprise ?</p>
                <div class="d-flex justify-content-around">
                    <button role="button" class="btn btn-outline-danger" @click="cancelOrder()">Confirmer l'annulation</button>
                    <button role="button" class="btn btn-gd-dark"  @click="closeModalCancelOrder()"><span class="text-white">Retour</span></button>
                </div>
            </template>
        </standard-modal>
    </basket-layout>
</template>
<style>
#yousign-iframe {

    height:100%;
}
</style>
